import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import FileTypedIcon from "nlib/common/FileTypedIcon";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback, useState } from "react";
import Utils from "utils/Utils";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const {
  DOCUMENT_FILE_TYPES: { PNG, JPG, TIFF },
  DATETIME_FORMATS: { DATETIME_TEXT }
} = Constants;

const IMAGE_TYPE_EXTENSIONS = [PNG, JPG, TIFF].map(({ extensions }) => extensions).flat();

const dateFromObjectId = (objectId) => {
  if (typeof objectId !== "string") return null;

  // Need the date the file was added
  // eslint-disable-next-line no-magic-numbers
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Attachment = ({ numbered, item, disabled, useConfirm = true, index, onDelete, onClick }) => {
  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { messages, documentUpdateActions } = useSelector(getTextsData);

  const {
    id = index,
    file,
    loading,
    originalName = "",
    createdAt = dateFromObjectId(id)
  } = item;

  const dateString = createdAt && moment(createdAt).format(DATETIME_TEXT);

  const imageType = IMAGE_TYPE_EXTENSIONS.includes(`.${originalName.split(".").pop().toLowerCase()}`);

  const [fetched, setFetched] = useState(false);

  const handleDeleteClick = useCallback(async() => {
    if (disabled || fetched) return;

    if (useConfirm) {
      const result = await showCommonModal({ confirm: true, text: messages.fileDeleteConfirm });

      if (!result) return;
    }

    onDelete(id);
  }, [disabled, fetched, id, messages, showCommonModal, useConfirm, onDelete]);

  const handleFileClick = useCallback(async() => {
    if (disabled || fetched) return;

    if (onClick) {
      onClick(item);
    } else {
      if (file) {
        Utils.downloadContent(URL.createObjectURL(file), file.name);

        return;
      }

      setFetched(true);

      const link = await dispatch(MainApiActions.fetchAttachmentUrl(item, true));

      setFetched(false);

      if (!link) return;

      Utils.downloadContent(link);
    }
  }, [file, disabled, fetched, onClick, item, dispatch]);

  return (
    <div className={Css.attachment} disabled={disabled || fetched || loading}>
      {!!numbered && (
        <div className={Css.number}>{index + 1}.</div>
      )}
      <div
        className={Css.icon}
        style={(imageType && file) ? { backgroundImage: `url(${URL.createObjectURL(file)})` } : undefined}
        onClick={handleFileClick}>
        {(!imageType || !file) && <FileTypedIcon fileName={originalName} />}
      </div>
      <div className={Css.info} onClick={handleFileClick}>
        <div className={Css.name}>{originalName}</div>
        {!!dateString && (
          <div className={Css.meta}>{documentUpdateActions.added} {dateString}</div>
        )}
      </div>
      {loading
        ? (
          <div className={Css.loading}>
            <Icons.Spinner data-wait />
          </div>
        )
        : (!!onDelete && (
          <div className={Css.delete} onClick={handleDeleteClick}>
            <Icons.Trash />
          </div>
        ))}
    </div>
  );
};

export default React.memo(Attachment);
