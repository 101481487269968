import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Checkbox, UploadFilesButton } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Attachments from "nlib/common/Attachments";
import Constants from "const/Constants";
import React, { useCallback, useMemo, useState } from "react";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "nlib/common/SideBar";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

const { DOCUMENT_FILE_TYPES } = Constants;

const { PNG, JPG, TIFF } = DOCUMENT_FILE_TYPES;

const IMAGE_TYPE_EXTENSIONS = [PNG, JPG, TIFF].map(({ extensions }) => extensions).flat();

const FILE_DROP_ALLOWED_TYPES = Object.values(DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const UploadDocumentsModal = ({ data: { files = [] } = {}, onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const [filesList, setFilesList] = useState(files);

  const [merge, setMerge] = useState(false);

  const handleAttachmentDelete = useCallback((index) => {
    setFilesList((prev) => {
      return prev.filter((file, fileIndex) => fileIndex !== index);
    });
  }, []);

  const attachments = useMemo(() => {
    return filesList.map((file, index) => ({ id: index, originalName: file.name || "", file }));
  }, [filesList]);

  const handleImportClick = useCallback(() => {
    onClose({ files: filesList, merge });
  }, [filesList, merge, onClose]);

  const handleCheckboxChange = useCallback(() => {
    setMerge((prev) => !prev);
  }, []);

  const handleFilesChange = useCallback((filesToAdd) => {
    setFilesList((prev) => [...prev, ...filesToAdd]);
  }, []);

  const handleAttachmentClick = useCallback((item) => {
    const { originalName = "", file } = item;

    const typeImage = IMAGE_TYPE_EXTENSIONS.includes(`.${originalName.split(".").pop().toLowerCase()}`);

    if (typeImage && file) {
      dispatch(UiActions.showModalImages([URL.createObjectURL(file)]));
    }
  }, [dispatch]);

  const handleCloseButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <SideBar className={Css.uploadDocumentsModal}>
      <SideBarHeader onCloseClick={handleCloseButtonClick}>
        <span>{uiTexts.uploadDocuments}</span>
      </SideBarHeader>
      <SideBarContent>
        <Attachments
          showAll numbered
          useConfirm={false}
          attachments={attachments}
          className={Css.attachments}
          onFileClick={handleAttachmentClick}
          onDelete={handleAttachmentDelete} />
      </SideBarContent>
      <SideBarFooter className={Css.footer}>
        <div>
          <div className={Css.merge}>
            <Button outline>
              <Checkbox
                toggle
                checked={merge}
                label={messages.mergeDocumentsCheckbox}
                className={Css.checkbox}
                labelClassName={Css.checkboxLabel}
                onChange={handleCheckboxChange} />
            </Button>
          </div>
        </div>
        <div className={Css.buttons}>
          {Utils.checkIsNativeAndroid() && (
            <UploadFilesButton
              outline dark
              className={Css.button}
              icon={Icons.Camera}
              capture="environment"
              accept={["image/*"]}
              onChange={handleFilesChange}>
              {uiTexts.takePhoto}
            </UploadFilesButton>
          )}
          <UploadFilesButton
            outline multiple
            className={Css.button}
            icon={Icons.FilePlus}
            accept={FILE_DROP_ALLOWED_TYPES}
            onChange={handleFilesChange}>
            {Utils.checkIsNativeAndroid()
              ? uiTexts.selectFiles
              : (merge ? uiTexts.addMorePages : uiTexts.addMoreDocuments)}
          </UploadFilesButton>
          <Button
            primary large
            className={Css.button}
            disabled={!filesList.length || (merge && filesList.length === 1)}
            onClick={handleImportClick}>
            <div>
              {filesList.length
                ? Utils.replaceTextVars(
                  merge ? uiTexts.mergeCountPages : uiTexts.uploadCountDocuments,
                  { count: filesList.length }
                )
                : (merge ? uiTexts.mergeDocuments : uiTexts.uploadDocuments)}
            </div>
          </Button>
        </div>
      </SideBarFooter>
    </SideBar>
  );
};

export default React.memo(UploadDocumentsModal);
